<template>
  <div class="container-fluid py-4 add-student">
 <div class="row">
   <div class="col-12">
     <div class="card my-4">
       <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
         <div
           class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
         >
           <h6 class="text-white text-capitalize ps-3">  تعديل بيانات العميل</h6>
         </div>
       </div>
       <div class="card-body px-0 pb-2">
                <form @submit="updateClient" role="form" class="text-start mt-3">
                    <div class="row">
             <div class="mb-3 col-lg-6">
                 <label>اسم العميل<span class="red">*</span>
                 </label>
               <vmd-input
                 id=""
                 type="text"
                v-model='client.name'
                 required
               />
             </div>
             <div class="mb-3 col-lg-6">
                 <label>  رقم الهاتف (  يجب أن يكون رقمًا مصريًا مكونًا من 10 رقمًا ويبدأ بـ 2+)<span class="red">*</span>
                   <br/>
                 </label>
               <vmd-input
                 id=""
                 class="ltr-inpt"
                 type="text"
                v-model='client.phone'
                 required
               />
               <label class='err-msg red' style="position:relative" v-if="errors.phone">{{errors?.phone[0]}}</label>
             </div>
             <div class="mb-3 col-lg-6">
                 <label>  الحفلة <span class="red">*</span>
                 </label>
                 <multiselect
                     class='required form-control'
                     id='grade'
                     v-model=" selectedEvent"
                     :options='events'
                     :searchable='true'
                     :close-on-select='true'
                     placeholder=" اختر الحفلة"
                     label='name'
                     :custom-label="customLabel"
                     track-by='id'
                     required='required'
                     :internal-search="true"
                     >
                   >
                     <template v-slot:noOptions>
                       <span> فارغة </span>
                     </template>
                     <template v-slot:noResult>
                       <span>لا يوجد نتيجة </span>
                     </template>
                   </multiselect>

             </div>
             </div>
                    <div class="row">
             <div class="text-center col-lg-6">
               <vmd-button
                 class="my-4 mb-2"
                 variant="gradient"
                 color="info"
                 > حفظ</vmd-button
               >
             </div>
              <div class="text-center col-lg-6">
               <vmd-button
               type='reset'
                 class="my-4 mb-2"
                 variant="gradient"
                 color="warning"
                 > رجوع</vmd-button
               >
             </div>
                    </div>
           </form>
     </div>
   </div>
   </div>
 </div>
 </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import swal from 'sweetalert'
export default {
  name: 'AddStudent',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      client: {},
      events: [],
      selectedEvent: '',
      errors: {}
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}clients/${this.$route.params.id}`)
      .then((response) => {
        this.client = response.data.data
        this.selectedEvent = {
          id: this.client?.event.id,
          name: this.client?.event.name
        }
      })
    this.$http.get(`${this.$hostUrl}events`)
      .then((response) => {
        this.events = response.data.data.map(value => ({
          name: value.name,
          id: value.id
        }))
      })
  },

  methods: {
    async updateClient (e) {
      e.preventDefault()
      const formData = new FormData()
      if (/^\d+$/.test(this.client.name)) {
        swal('اسم الحفلة يجب ان يحتوي على حروف')
        return 0
      }
      formData.append('name', this.client.name)
      formData.append('phone', this.client.phone)
      formData.append('event_id', this.selectedEvent.id)
      formData.append('_method', 'PUT')
      this.errors = await this.postRequest(formData, this.$hostUrl + 'clients/' + this.$route.params.id, '/clients')
    }
  }
}
</script>
<style >
.error{
color:#f44335 !important;
font-size:13px;
padding-right: 25px;
}

.unrequired{
 display: flex;
 gap: 5px;
}
.li-h{
line-height:87px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
 padding: 20px !important;
 margin-top: 26px !important;
 margin-left: unset !important
}

</style>
